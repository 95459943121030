import type { ParamMatcher } from "@sveltejs/kit";
import cfg from "$lib/config/config";

const validMarketHandles = cfg.AVAILABLE_MARKETS.map(m => m.key.toLowerCase());
const validCountryRegex = new RegExp(`^(${validMarketHandles.join("|")})$`);

export const match: ParamMatcher = (param: string) => {
  // Check if market matches one of our markets from Shopify.
  return validCountryRegex.test(param);
};
