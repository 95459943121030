import type { ParamMatcher } from "@sveltejs/kit";
import { LanguageCode } from "$lib/gql/shopifygen/codegen";

const validLanguageCodes = Object.values(LanguageCode).map(c =>
  c.toLowerCase(),
);
const validLanguageRegex = new RegExp(`^(${validLanguageCodes.join("|")})$`);

export const match: ParamMatcher = param => {
  // Check if lang is a two character string and matches one of our country codes from Shopify.
  return validLanguageRegex.test(param);
};
